import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";
import { addPrivateNotesToConversation } from "./conversation";
import { getOpenedReachedTodos } from "./notifications";
import { formatDate } from "../utilities/formatDate";
const initialState = {
  todolist: { opened: [], assigned: [], completed: [] },
  todolistMeta: [],
  status: "idle",
  statusUpdateTodolist: "idle",
  statusCreateTodo: "idle",
  errorCreateTodo: null,
  fetchPrivateNotesStatus: "idle",
  statusEditPrivateNote: "idle",
  createPrivateNoteStatus: "idle",
  operator: "",
  title: "",
  IssueItemTags: [],
  ShowIssueItemTags: [],
  ToDoDescription: "",
  date: "",
  error: null,
  statusCreateComment: "idle",
  errorCreateComment: null,
  todosLength: {},
};
export const gettodolist = createAsyncThunk(
  "todolist/gettodolist",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body.websiteID}/to-do-list${body.query}`
      );
      data = response.data.data;
      if (response.status === 200) {
        return { data, query: body.query.split("&")[0] };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updatetodolist = createAsyncThunk(
  "todolist/updatetodolist",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/websites/${body.websiteID}/conversations/${body.conversationID}/to-do-list/${body.id}`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        ((body?.query?.includes("true") && data?.sandbox === false) ||
          (body?.query?.includes("false") && data?.sandbox === true) ||
          body?.data?.assigned_to ||
          (body?.query?.includes("assigned_to") &&
            data?.sandbox !== undefined) ||
          body?.query === "messaging-page") &&
          (() => {
            thunkAPI.dispatch(getTodosLength(body?.websiteID));
            body?.user?.role?.code === "OPERATOR" &&
              thunkAPI.dispatch(getOpenedReachedTodos(body?.websiteID));
          })();

        return {
          data,
          query: body.query,
          issueDetails: body?.issueDetails,
          user: body.user,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createTodo = createAsyncThunk(
  "todolist/createTodo",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body?.websiteId}/conversations/${body.conversationId}/to-do-list`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getTodosLength(body.websiteId));
        body?.user?._id === body?.data?.assigned_to &&
          body?.data?.due_date === formatDate(new Date()) &&
          thunkAPI.dispatch(getOpenedReachedTodos(body?.websiteId));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchPrivateNote = createAsyncThunk(
  "todolist/fetchPrivateNote",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `/websites/${body?.websiteID}/conversations/${body.conversationID}/notes/`
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          addPrivateNotesToConversation({
            data,
            conversationId: body.conversationID,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deletePrivateNote = createAsyncThunk(
  "todolist/deletePrivateNote",
  async (body, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/websites/${body?.websiteID}/conversations/${body.conversationID}/notes/${body.id}`
      );
      if (response.status === 200) {
        thunkAPI.dispatch(
          fetchPrivateNote({
            websiteID: body?.websiteID,
            conversationID: body.conversationID,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updatePrivateNote = createAsyncThunk(
  "todolist/updatePrivateNote",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/websites/${body?.websiteID}/conversations/${body.conversationID}/notes/${body.id}`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          fetchPrivateNote({
            websiteID: body?.websiteID,
            conversationID: body.conversationID,
          })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const createPrivateNote = createAsyncThunk(
  "todolist/createPrivateNote",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/websites/${body?.websiteID}/conversations/${body.conversationID}/notes/`,
        body.data
      );
      data = response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(addPrivateNotesToConversation(data));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createComment = createAsyncThunk(
  "todolist/createComment",
  async (body) => {
    try {
      let file;
      let response;

      if (body?.file) {
        body?.comment?.trim() !== ""
          ? (file = serialize({ file: body?.file, content: body?.comment }))
          : (file = serialize({ file: body?.file }));
        response = await axios.post(
          `/websites/${body.websiteID}/to-do-list/${body.todoID}/comments`,
          file
        );
      } else {
        response = await axios.post(
          `/websites/${body.websiteID}/to-do-list/${body.todoID}/comments`,
          { content: body.comment }
        );
      }

      if (response.status === 200) {
        return { data: response.data.data, todoID: body.todoID };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message ? err.message : response.data.data?.message
      );
    }
  }
);

export const getTodosLength = createAsyncThunk(
  "todolist/getTodosLength",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteID}/getmytodos`);
      data = response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "todolist",
  initialState,
  reducers: {
    getTitleSuccess: (state, action) => {
      state.title = action.payload;
    },
    getOperatorSuccess: (state, action) => {
      state.operator = action.payload;
    },
    getDateSuccess: (state, action) => {
      state.date = action.payload;
    },
    getTags: (state, action) => {
      if (
        state.IssueItemTags.find((item) => item === action.payload.id) &&
        state.ShowIssueItemTags.find((item) => item.id === action.payload.id)
      ) {
        state.IssueItemTags = state.IssueItemTags.map((item) =>
          item.id === action.payload.id ? action.payload.id : item
        );
        state.ShowIssueItemTags = state.ShowIssueItemTags.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
      } else {
        state.IssueItemTags.push(action.payload.id);
        state.ShowIssueItemTags.push(action.payload);
      }
    },
    emptyTagsArray: (state) => {
      state.IssueItemTags = [];
      state.ShowIssueItemTags = [];
    },
    clearData: (state) => {
      state.title = "";
      state.operator = "";
      state.date = "";
      state.IssueItemTags = [];
      state.ShowIssueItemTags = [];
    },
  },
  extraReducers: {
    [gettodolist.pending]: (state) => {
      state.status = "loading";
    },
    [gettodolist.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.todolistMeta = action.payload.data.totalPages;
      action.payload.query === "?sandbox=false" &&
        (state.todolist.opened = state.todolist.opened.concat(
          action.payload.data.docs
        ));
      action.payload.query === "?sandbox=true" &&
        (state.todolist.completed = state.todolist.completed.concat(
          action.payload.data.docs
        ));
      action.payload.query.includes("assigned") &&
        (state.todolist.assigned = state.todolist.assigned.concat(
          action.payload.data.docs
        ));
    },
    [gettodolist.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [getTodosLength.fulfilled]: (state, action) => {
      state.todosLength = action.payload;
    },

    [createTodo.pending]: (state) => {
      state.statusCreateTodo = "loading";
    },
    [createTodo.fulfilled]: (state, action) => {
      state.statusCreateTodo = "succeeded";
      state?.todolist?.opened?.length > 0 &&
        (state.todolist.opened = [action.payload, ...state?.todolist?.opened]);
    },
    [createTodo.rejected]: (state, action) => {
      state.statusCreateTodo = "failed";
      state.errorCreateTodo = action.error;
    },
    [updatePrivateNote.pending]: (state) => {
      state.statusEditPrivateNote = "loading";
    },
    [updatePrivateNote.fulfilled]: (state) => {
      state.statusEditPrivateNote = "succeeded";
    },
    [updatePrivateNote.rejected]: (state, action) => {
      state.statusEditPrivateNote = "failed";
      state.statusEditPrivateNote = action.error;
    },
    [createPrivateNote.pending]: (state) => {
      state.createPrivateNoteStatus = "loading";
    },
    [createPrivateNote.fulfilled]: (state) => {
      state.createPrivateNoteStatus = "succeeded";
    },
    [createPrivateNote.rejected]: (state, action) => {
      state.createPrivateNoteStatus = "failed";
      state.createPrivateNoteStatus = action.error;
    },
    [fetchPrivateNote.pending]: (state) => {
      state.fetchPrivateNotesStatus = "loading";
    },
    [fetchPrivateNote.fulfilled]: (state) => {
      state.fetchPrivateNotesStatus = "succeeded";
    },
    [fetchPrivateNote.rejected]: (state) => {
      state.fetchPrivateNotesStatus = "failed";
    },
    [updatetodolist.pending]: (state) => {
      state.statusUpdateTodolist = "loading";
    },
    [updatetodolist.fulfilled]: (state, action) => {
      state.statusUpdateTodolist = "succeeded";

      !action.payload.issueDetails
        ? (() => {
            // assigned todolist always change without condition of query

            state.todolist.assigned = state.todolist.assigned?.map((todolist) =>
              todolist?._id === action.payload.data?._id
                ? action.payload.data
                : todolist
            );
            action.payload.query === "?sandbox=false" &&
              (() => {
                state.todolist.opened = state.todolist.opened?.filter(
                  (todolist) => todolist?._id !== action.payload.data?._id
                );
                state.todolist.completed?.length !== 0 &&
                  (state.todolist.completed = [
                    action.payload.data,
                    ...state.todolist.completed,
                  ]);
              })();

            action.payload.query === "?sandbox=true" &&
              (() => {
                state.todolist.completed = state.todolist.completed?.filter(
                  (todolist) => todolist?._id !== action.payload.data?._id
                );
                state.todolist.opened?.length !== 0 &&
                  (state.todolist.opened = [
                    action.payload.data,
                    ...state.todolist.opened,
                  ]);
              })();

            (action.payload.query.includes("assigned") ||
              action.payload.query.includes("messaging")) &&
              (() => {
                action.payload.data?.sandbox === true
                  ? (() => {
                      state.todolist.completed?.length !== 0 &&
                        (state.todolist.completed = [
                          action.payload.data,
                          ...state.todolist.completed,
                        ]);
                      state.todolist.opened = state.todolist.opened.filter(
                        (todolist) => todolist?._id !== action.payload.data?._id
                      );
                    })()
                  : (() => {
                      state.todolist.opened?.length !== 0 &&
                        (state.todolist.opened = [
                          action.payload.data,
                          ...state.todolist.opened,
                        ]);
                      state.todolist.completed =
                        state.todolist.completed.filter(
                          (todolist) =>
                            todolist?._id !== action.payload.data?._id
                        );
                    })();
              })();
          })()
        : (() => {
            state.todolist.opened = state.todolist.opened.map((item) =>
              item?._id === action.payload.data?._id
                ? action.payload.data
                : item
            );
            state.operator !== ""
              ? (() => {
                  state.operator._id !== action.payload.user?._id
                    ? (state.todolist.assigned = state.todolist.assigned.filter(
                        (todolist) => todolist?._id !== action.payload.data?._id
                      ))
                    : state.todolist.assigned?.length !== 0 &&
                      (state.todolist.assigned = [
                        action.payload.data,
                        ...state.todolist.assigned,
                      ]);
                })()
              : (state.todolist.assigned = state.todolist.assigned.map(
                  (todolist) =>
                    todolist?._id === action.payload.data?._id
                      ? action.payload.data
                      : todolist
                ));

            state.todolist.completed = state.todolist.completed.map(
              (todolist) =>
                todolist?._id === action.payload.data?._id
                  ? action.payload.data
                  : todolist
            );

            state.operator = "";
            state.title = "";
            state.date = "";
          })();
    },
    [updatetodolist.rejected]: (state, action) => {
      state.statusUpdateTodolist = "failed";
      state.error = action.payload;
    },
    [createComment.pending]: (state) => {
      state.statusCreateComment = "loading";
    },
    [createComment.fulfilled]: (state, action) => {
      state.statusCreateComment = "success";
      state.todolist.opened = state.todolist.opened.map((item) =>
        item._id === action.payload.todoID
          ? { ...item, comments: [...item.comments, action.payload.data] }
          : item
      );
      state.todolist.assigned = state.todolist.assigned.map((item) =>
        item._id === action.payload.todoID
          ? { ...item, comments: [...item.comments, action.payload.data] }
          : item
      );
      state.todolist.completed = state.todolist.completed.map((item) =>
        item._id === action.payload.todoID
          ? { ...item, comments: [...item.comments, action.payload.data] }
          : item
      );
    },
    [createComment.rejected]: (state, action) => {
      state.statusCreateComment = "failed";
      state.errorCreateComment = action.payload;
    },
  },
});
const { getTitleSuccess, getOperatorSuccess, getDateSuccess } = slice.actions;
export const { getTags, emptyTagsArray, clearData } = slice.actions;

export const reducer = slice.reducer;
export default slice;
export const getTitle = (title) => async (dispatch) => {
  try {
    await dispatch(getTitleSuccess(title));
  } catch (error) {
    return console.error(error.message);
  }
};
export const getOperator = (operator) => async (dispatch) => {
  try {
    await dispatch(getOperatorSuccess(operator));
  } catch (error) {
    return console.error(error.message);
  }
};
export const getDate = (date) => async (dispatch) => {
  try {
    await dispatch(getDateSuccess(date));
  } catch (error) {
    return console.error(error.message);
  }
};
